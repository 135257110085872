import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ACLDATA } from './acl';

@Injectable({ providedIn: 'root' })
export class AclService {
  //FEATURE - ACL MAP
  // FEATURE_BIT_MAP = {
  //   'settings/audit': 1,
  //   'settings/ip_filter': 3,
  // };

  // userInfo:any;
  constructor(private authService: AuthService) {
    // this.authService.userInfoSub.subscribe(res => {this.userInfo = res;console.log('acl',res);});
    /*
    if (!this.authService.userInfo) {
      this.authService.getUserInfo().subscribe((res) => {});
    }
    */
  }

  // Updated the below code in AclAllowPipe /cms_webclient_v2/src/app/shared/pipe/acl-allow.pipe.ts

  // isFeatureAllowed(resource: string) {
  //   const bitPos = this.FEATURE_BIT_MAP[resource];
  //   //Enable IP filter for all customers
  //   if(resource == 'settings/ip_filter'){
  //     return true;
  //   }
  //   const feature = this.authService.userInfo.feature;
  //   if (bitPos == undefined || feature == undefined) {
  //     return true;
  //   }
  //   if (feature == 0 && bitPos > 0) {
  //     return false;
  //   }
  //   return (feature & (1 << (bitPos - 1))) != 0;
  // }

  // allow(resource: string): boolean {
  //   if (!this.authService.userInfo) return false;

  //   const userRole = this.authService.userInfo.urole;
  //   const roles = ACLDATA[resource];
  //   return roles?.includes(userRole) && this.isFeatureAllowed(resource);
  // }

  restrictedCompViewer(): boolean {
    const role = this.authService.userInfo?.urole;
    switch (role) {
      case 'ADMIN':
      case 'CMS_VIEWER':
      case 'BROADCAST_MANAGER':
        return false;
    }
    return true;
  }

  canAssociateContent(): boolean {
    return this.authService.userInfo.canassociate;
  }

  getDefaultRoute() {
    const role = this.authService.userInfo?.urole;
    switch (role) {
      case 'ADMIN':
      case 'DISPLAY_MANAGER':
      case 'CMS_VIEWER':
      case 'BROADCAST_MANAGER':
      default:
        return '/home/displays';
      case 'CONTENT_MANAGER':
        return '/home/media-library';
      case 'POP_MANAGER':
        return '/home/report';
    }
  }

  hasRole(roles: string[]): boolean {
    if (!this.authService.userInfo) {
      return false;
    }
    const regexRoles = new RegExp(roles.join('|'), 'i');
    const userRole = this.authService.userInfo.urole;
    return regexRoles.test(userRole);
  }
}
